import React, { Component } from "react";
import { Table as BarnTable } from "@centrate-io/barn";
import "./Table.scss";

class Table extends Component {
  render() {
    const props = { ...this.props };
    props.className = "pp-table " + (props.className || "");
    return <BarnTable {...props} />;
  }
}

export default Table;
