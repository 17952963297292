import React, { useState } from "react";
import {
  AdvancedMarker,
  InfoWindow,
  useAdvancedMarkerRef,
} from "@vis.gl/react-google-maps";

function Marker(props) {
  const [infowindowOpen, setInfowindowOpen] = useState(false);
  const [markerRef, marker] = useAdvancedMarkerRef();

  const position = {
    lat: props.lat,
    lng: props.lng,
  };

  return (
    <>
      <AdvancedMarker
        ref={markerRef}
        onClick={() => setInfowindowOpen(true)}
        position={position}
        title={props.name}
      />
      {infowindowOpen && (
        <InfoWindow
          anchor={marker}
          maxWidth={200}
          onCloseClick={() => setInfowindowOpen(false)}
        >
          <h3 style={{ margin: "0 0 8px 0" }}>
            <b>
              <u>{props.name}:</u>
            </b>
          </h3>
          <b>Date:</b> <span>{props.date}</span>
          <br />
          <b>Latitude:</b> <span>{position.lat}</span>
          <br />
          <b>Longitude:</b> <span>{position.lng}</span>
        </InfoWindow>
      )}
    </>
  );
}

export default Marker;
