import React, { useState } from 'react';
import { 
  Actions,
  Autocomplete,
  Checkbox,
  Header,
  Input, 
  Label,
  Progress,
  Subheader 
} from '_styleguide';
import { getForm } from '_helpers/form';

function Location(props) {
  const [address, setAddress] = useState(getForm('location'));

  // Update form by field and value
  const ua = values => setAddress({...address, ...values});

  return (
    <div className="step-location">
      <Progress className="sky-progress" percent={props.progress} showInfo={false} />
      <Header>Location</Header>
      <Subheader>Where are you located?</Subheader>
      <div className="app-form">
        <div className="form-item">
          <Label htmlFor="address">Address</Label>
          <Autocomplete
            value={address.address}
            onChange={v => ua({ address: v })} 
            updateAddress={ua}
          />
        </div>
        <div className="form-item">
          <Label htmlFor="city">City</Label>
          <Input
            id="city"
            value={address.city} 
            onChange={e => ua({ city: e.target.value })} 

            size="large" 
          />
        </div>
        <div className="form-row">
          <div>
            <Label htmlFor="state">State</Label>
            <Input
              id="state"
              value={address.state} 
              onChange={e => ua({ state: e.target.value })}
              size="large" 
            />
          </div>
          <div style={{ maxWidth: '160px' }}>
            <Label htmlFor="zip">Zip</Label>
            <Input
              id="zip"
              value={address.zip} 
              onChange={e => ua({ zip: e.target.value })} 
              size="large" 
            />
          </div>
        </div>
        <div className="form-item">
          <Checkbox
            id="ownership"
            checked={address.ownership} 
            onChange={e => ua({ ownership: e.target.checked })} 
          >
            I am the owner of this property
          </Checkbox>
        </div>
        
      </div>
      <Actions {...props} form={address} application={['zoho', 'vergent']} />
    </div>
  );
}

export default Location;
