const formStructure = {
  verification: {
    phone: undefined,
    verified: false
  },
  quote: {
    result: undefined,
    quote_amount: undefined
  },
  personal: {
    first_name: undefined,
    last_name: undefined,
    email: undefined,
    amount: undefined
  },
  location: {
    address: undefined,
    city: undefined,
    state: undefined,
    zip: undefined,
    ownership: false
  },
  car: {
    make: undefined,
    model: undefined,
    year: undefined,
    mileage: undefined,
    vin: undefined,
  },
  income: {
    ssn: undefined,
    ssn_confirm: undefined,
    employer: undefined,
    employment_start: undefined,
    employer_phone: undefined,
    gross_pay: undefined,
    pay_frequency: undefined,
    last_pay_date: undefined,
    pay_method: undefined,
    payday_recently: false
  },
  bank: {
    bank_name: undefined,
    account_type: undefined,
    routing_number: undefined,
    account_number: undefined
  },
  identification: {
    id_type: undefined,
    id_number: undefined,
    issue_date: undefined,
    expiration_date: undefined,
    id_state: undefined,
    marital_status: false,
    dependents: undefined
  }
};

export const hasCategory = category => {
  return formStructure[category] ? true : false;
};

export const getForm = category => {
  let currentForm = undefined;

  // Attempt to grab form data or use blank form
  try {
    const parsedForm = JSON.parse(localStorage.getItem('sky_form'));
    currentForm = parsedForm || Object.assign({}, formStructure);
  } catch (err) {
    currentForm =  Object.assign({}, formStructure);
  }

  // Return entire form or just a part depending on what is requested
  if (category) {
    return currentForm[category] || formStructure[category];
  } else {
    return currentForm;
  }
};

export const saveForm = (data, category) => {
  let currentForm = getForm();

  if (category) {
    currentForm[category] = {
      ...formStructure[category],
      ...currentForm[category],
      ...data
    };
  } else {
    currentForm = Object.assign({}, data);
  }
  console.log('Saving form: ', currentForm);
  localStorage.setItem('sky_form', JSON.stringify(currentForm));
  return currentForm;
};