import React, { useState } from 'react';
import { 
  Actions,
  Checkbox,
  DatePicker,
  Header,
  Input,
  InputNumber,
  Label,
  Progress,
  Subheader,
  Select
} from '_styleguide';
import { STATE_DATA } from '_assets/js/constants';
import { getForm } from '_helpers/form';

function Identification(props) {
  const [form, setForm] = useState(getForm('identification'));

  // Update form by field and value
  const u = (f, v) => { 
    const n = {...form};
    n[f] = v;
    setForm(n); 
  };  

  return (
    <div className="step-loan">
      <Progress className="sky-progress" percent={props.progress} showInfo={false} />
      <Header>Identification</Header>
      <Subheader>Verify your identity</Subheader>
      <div className="app-form">
        <div className="form-item">
          <Label htmlFor="routing_number">Type of identification</Label>
          <Select
            value={form.id_type} 
            onChange={v => u('id_type', v)}
            style={{ width: '100%' }}
            options={[
              { value: 'license', label: 'Driver\'s License' },
              { value: 'passport', label: 'Passport' },
              { value: 'state_id', label: 'State ID' },
              { value: 'foreign_id', label: 'Foreign ID' },
            ]}
            size="large"
          />
        </div>
        <div className="form-item">
          <Label htmlFor="routing_number">Identification number</Label>
          <Input
            id="id_number"
            value={form.id_number} 
            onChange={e => u('id_number', e.target.value)} 
            size="large" 
          />
        </div>
        <div className="form-row">
          <div>
            <Label htmlFor="bank_name">Issue date</Label>
            <DatePicker 
              id="issue_date"
              placeholder=""
              style={{ width: '100%' }}
              value={form.issue_date} 
              onChange={(d, ds) => u('issue_date', d)}
              size="large"  
            />
          </div>
          <div>
            <Label htmlFor="account_type">Expiration date</Label>
            <DatePicker 
              id="expiration_date"
              placeholder=""
              style={{ width: '100%' }}
              value={form.expiration_date} 
              onChange={(d, ds) => u('expiration_date', d)}
              size="large"  
            />
          </div>
        </div>
        <div className="form-item">
          <Label htmlFor="id_state">State of identification</Label>
          <Select
            value={form.id_state} 
            onChange={v => u('id_state', v)}
            style={{ width: '100%' }}
            options={STATE_DATA}
            size="large"
          />
        </div>
        <div className="form-item">
          <Checkbox
            id="marital_status"
            checked={form.marital_status} 
            onChange={e => u('marital_status', e.target.checked)}
          >
            Are you currently married?
          </Checkbox>
        </div>
        <div className="form-item">
          <Label htmlFor="dependents">Number of dependents</Label>
          <InputNumber
            id="dependents"
            style={{ width: '100%' }}
            min={1}
            max={20}
            value={form.dependents}
            onChange={v => u('gross_pay', v)} 
            size="large" 
          />
        </div>
      </div>
      <Actions {...props} form={form} />
    </div>
  );
}

export default Identification;
