import React, { useState } from 'react';
import { 
  Button,
  Header,
  Input, 
  Label,
  Subheader,
  message
} from '_styleguide';
import axios from 'axios';
import { FormatPhone } from '_assets/js/helpers';
import { saveForm } from '_helpers/form';
import { useNavigate } from 'react-router-dom';

function PersonalInformation(props) {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    phone: undefined,
    code: undefined
  });
  const [verificationSent, setVerificationSent] = useState(false);
  const [loading, setLoading] = useState(false);

  // Update form by field and value
  const u = (f, v) => { 
    const n = {...form}; 
    n[f] = f === 'phone' ? FormatPhone(v) : v; 
    setForm(n); 
  };

  // Submit login and receive token
  const sendVerification = async () => {
    if (form.phone?.length < 12) return message.error('Invalid phone number, try again');
    setLoading(true);
    const data = { phone: form.phone };
    axios.post(`${process.env.REACT_APP_API_PATH}/verification`, data).then(response => {
      setLoading(false);
      setVerificationSent(true);
      message.success('Verification code sent to ' + form.phone);
    }).catch(err => {
      message.error('Verification failed to send, try again')
      setLoading(false);
    });
  }

  // Submit login and receive token
  const submitVerification = async () => {
    if (form.code?.length < 6) return message.error('Invalid code, try again');
    setLoading(true);
    const data = { phone: form.phone, code: form.code };
    axios.post(`${process.env.REACT_APP_API_PATH}/verification/submit`, data).then(response => {
      setLoading(false);
      setVerificationSent(true);
      message.success('Phone number successfully verified');
      saveForm({ phone: form.phone, verified: true }, 'verification');
      navigate('/personal');
    }).catch(err => {
      message.error('Verification failed, try again')
      setLoading(false);
    });
  }

  return !verificationSent ? (
    <div className="step-start">
      <Header>Let's get started</Header>
      <Subheader>Enter your phone number to get started</Subheader>
      <div className="app-form">
        <div className="form-item">
          <Label htmlFor="phone">Phone number</Label>
          <Input
            id="phone"
            value={form.phone} 
            onChange={e => u('phone', e.target.value)} 
            size="large" 
          />
        </div>
      </div>
      <div className="sky-actions">
        <Button loading={loading} type="primary" size="large" block onClick={sendVerification}>Next</Button>
      </div>
    </div>
  ) : (
    <div className="step-start">
      <Header>Enter verification code</Header>
      <Subheader>Please enter the code sent to {form.phone}</Subheader>
      <div className="app-form">
        <div className="form-item">
          <Label htmlFor="code">Enter code</Label>
          <Input
            id="code"
            value={form.code} 
            onChange={e => u('code', e.target.value)} 
            size="large" 
          />
        </div>
      </div>
      <div className="sky-actions">
        <Button loading={loading} type="primary" size="large" block onClick={submitVerification}>Next</Button>
      </div>
    </div>
  );
}

export default PersonalInformation;
