import React, { useState } from 'react';
import { 
  Actions,
  Checkbox,
  DatePicker,
  Header,
  Input,
  InputNumber,
  Label,
  Progress,
  Subheader,
  Select
} from '_styleguide';
import { FormatSSN, FormatPhone } from '_assets/js/helpers';
import { getForm } from '_helpers/form';

function Income(props) {
  const [form, setForm] = useState(getForm('income'));

  // Update form by field and value
  const u = (f, v) => { 
    const n = {...form};
    if (f === 'ssn' || f === 'ssn_confirm') {
      n[f] = FormatSSN(v);
    } else if (f === 'employer_phone') {
      n[f] = FormatPhone(v);
    } else {
      n[f] = v;
    }
    setForm(n); 
  };  

  return (
    <div className="step-loan">
      <Progress className="sky-progress" percent={props.progress} showInfo={false} />
      <Header>Income Information</Header>
      <Subheader>We need some financial information</Subheader>
      <div className="app-form">
        <div className="form-row">
          <div>
            <Label htmlFor="ssn">SSN #</Label>
            <Input
              id="ssn"
              value={form.ssn} 
              onChange={e => u('ssn', e.target.value)} 
              size="large" 
            />
          </div>
          <div>
            <Label htmlFor="ssn-confirm">Confirm SSN #</Label>
            <Input
              id="ssn-confirm"
              value={form.ssn_confirm} 
              onChange={e => u('ssn_confirm', e.target.value)} 
              size="large" 
            />
          </div>
        </div>
        <div className="form-item">
          <Label htmlFor="employer">Employer name</Label>
          <Input
            id="employer"
            value={form.employer} 
            onChange={e => u('employer', e.target.value)} 
            size="large" 
          />
        </div>
        <div className="form-item">
          <Label htmlFor="employer_phone">Employer phone</Label>
          <Input
            id="employer_phone"
            value={form.employer_phone} 
            onChange={e => u('employer_phone', e.target.value)} 
            size="large" 
          />
        </div>
        <div className="form-item">
          <Label htmlFor="employment_start">Employment start date</Label>
          <DatePicker 
            id="employment_start"
            placeholder=""
            style={{ width: '100%' }}
            value={form.employment_start} 
            onChange={(d, ds) => u('employment_start', d)}
            size="large"  
          />
        </div>
        <div className="form-row">
          <div>
            <Label htmlFor="gross_pay">Gross pay</Label>
            <InputNumber
              id="gross_pay"
              style={{ width: '100%' }}
              min={1}
              max={10000000}
              value={form.gross_pay}
              formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              onChange={v => u('gross_pay', v)} 
              size="large" 
            />
          </div>
          <div>
            <Label htmlFor="pay_frequency">Frequency</Label>
            <Select
              value={form.pay_frequency} 
              onChange={v => u('pay_frequency', v)}
              style={{ width: '100%' }}
              options={[
                { value: 'weekly', label: 'Weekly' },
                { value: 'bi-weekly', label: 'Bi-Weekly' },
                { value: 'semi-monthly', label: 'Semi-Monthly' },
                { value: 'monthly', label: 'Monthly' },
              ]}
              size="large"
            />
          </div>
        </div>
        <div className="form-row">
          <div>
            <Label htmlFor="last_pay_date">Last pay date</Label>
            <DatePicker 
              id="last_pay_date"
              placeholder=""
              style={{ width: '100%' }}
              value={form.last_pay_date} 
              onChange={(d, ds) => u('last_pay_date', d)}
              size="large"  
            />
          </div>
          <div>
            <Label htmlFor="pay_method">Payment method</Label>
            <Select
              value={form.pay_method} 
              onChange={v => u('pay_method', v)}
              style={{ width: '100%' }}
              options={[
                { value: 'direct', label: 'Direct deposit' },
                { value: 'check', label: 'Check' },
                { value: 'cash', label: 'Cash' },
              ]}
              size="large"
            />
          </div>
        </div>
        <div className="form-item">
          <Checkbox
            id="payday_recently"
            checked={form.payday_recently} 
            onChange={e => u({ payday_recently: e.target.checked })} 
          >
            Have you taken out a Payday Loan in the past 6 months?
          </Checkbox>
        </div>
      </div>
      <Actions {...props} form={form} />
    </div>
  );
}

export default Income;
