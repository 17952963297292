import { 
  Actions,
  Result
} from '_styleguide';
import { getForm } from '_helpers/form';

function Quote(props) {
  const personal = getForm('personal');
  const form = getForm('quote');

  // Results = pre-vin, pre-make, denied-blackbook, denied-prequal

  let results = {
    status: 'success',
    title: `Congrats you've been pre-approved for up to $${form.quote_amount || 0}`,
    subTitle: `Click next to start claiming your money`
  };
  if (form.result === 'pre-vin' || form.result === 'pre-make') {
    if (form.quote_amount > personal.amount) {
      results = {
        status: 'success',
        title: `Congrats you've been pre-approved for up to $${form.quote_amount || 0}`,
        subTitle: `Click next to start claiming your money`
      };
    } else {
      results = {
        status: 'success',
        title: `You have been approved for $${form.quote_amount} based on the value of your vehicle`,
        subTitle: `Based on the value of your car, you have been pre-approved for the amount above, click next to start claiming your money`
      };
    }
  }

  if (form.result === 'denied-blackbook') {
    results = {
      status: 'warning',
      title: `Need more information`,
      subTitle: `We were unable to provide a quote based on the information you provided, to start another application click below`
    };
  }

  if (form.result === 'denied-prequal') {
    results = {
      status: 'error',
      title: `Unable to provide a quote`,
      subTitle: `Based on the value of your car, we are unable to provide a quote at this time, click below to try again with a different car`
    };
  }

  return (
    <div className="step-start">
      <Result {...results} />
      <Actions {...props} />
    </div>
  );
}

export default Quote;
