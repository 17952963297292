import {
  IconMap2,
  IconChartAreaLine,
  IconUsers,
  IconLock,
} from "@tabler/icons-react";
export const getCrumbs = (route) => {
  if (route === "mobileusers") {
    return {
      title: "Mobile Users",
      icon: <IconUsers />,
      description: "Current mobile app applications",
    };
  }
  if (route === "location") {
    return {
      title: "Location Map",
      icon: <IconMap2 />,
      description: "Track your users previous locations",
    };
  }
  if (route === "analytics") {
    return {
      title: "Analytics",
      icon: <IconChartAreaLine />,
      description: "Track statistics and make informed decisions",
    };
  }
  if (route === "users") {
    return {
      title: "Users",
      icon: <IconUsers />,
      description: "Manage your team",
    };
  }
  return {
    title: "Admin Dashboard",
    icon: <IconLock />,
    description: "Manage your account",
  };
};
