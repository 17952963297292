import { useState } from 'react';
import { BrowserRouter, redirect } from 'react-router-dom';
import { getUser } from '_helpers/auth';
import Auth from 'App/Auth/Auth';
import Admin from 'App/Admin/Admin';
import Application from 'App/Application/Application';

function App(props) {
  const [user, setUser] = useState(getUser());
  const login = token => {
    localStorage.setItem('JWT', token);
    setUser(getUser());
    redirect('/');
  };

  const globalProps = { login, user };

  const auth = <Auth {...globalProps} />;
  const main = <Application {...globalProps} />;
  const admin = <Admin {...globalProps} />;

  // Determine which routes to show based on user
  // This lets ensures security and disables access across types
  let routes = auth;
  if (user?.role === 'admin') {
    routes = admin;
  } else if (['/admin', '/admin/'].includes(window.location.pathname)) {
    routes = auth;
  } else {
    routes = main;
  }

  return <BrowserRouter>{routes}</BrowserRouter>;
}

export default App;
