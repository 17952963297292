import React, { useState } from "react";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import Location from "./Location/Location";
import LastLocation from "./LastLocation/LastLocation";
import MobileUsers from "./MobileUsers/MobileUsers";
import KyckEmails from "./KyckEmails/KyckEmails";
import { IconUsers, IconMailbox, IconMap2 } from "@tabler/icons-react";
import { Layout, Menu } from "antd";
import Company from "./_components/Company/Company";
import { Header, Text } from "@centrate-io/barn";
import "./Admin.scss";

const { Content, Sider } = Layout;

const getItem = (label, key, icon, children) => ({
  key,
  icon,
  children,
  label,
});
const items = [
  getItem(<Link to="/">Mobile Users</Link>, "1", <IconUsers />),
  getItem(<Link to="/location">Location Map</Link>, "2", <IconMap2 />),
  getItem(<Link to="/kyck-emails">Kyck Emails</Link>, "3", <IconMailbox />),
];

function Admin(props) {
  const [collapsed, setCollapsed] = useState(false);
  const [currentCount, setCurrentCount] = useState();
  const [crumbs, setCrumbs] = useState();

  let location = useLocation();
  const path = location?.pathname;
  const tablePaths = ["/"];
  const mapPaths = ["/location", "/last-location"];

  const globalProps = {
    setCurrentCount,
    setCrumbs,
    collapsed,
  };

  const mapIncludes = () => {
    let mapRes = false;
    mapPaths.forEach((p) => {
      if (path.includes(p)) mapRes = true;
    });
    if (mapPaths.includes(path)) mapRes = true;
    return mapRes;
  };

  return (
    <Layout className="layout-admin" hasSider>
      <Sider
        width={240}
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <Company user={props?.user} collapsed={collapsed} />
        <Menu
          theme="light"
          mode="inline"
          defaultSelectedKeys={["1"]}
          items={items}
        />
      </Sider>
      <Layout className={`site-layout ${collapsed ? "collapsed" : ""}`}>
        <Content
          className={`site-content  ${tablePaths.includes(path) && "sheet"} ${mapIncludes() && "map"}`}
        >
          <div className="admin-header">
            {crumbs ? (
              <div className="crumbs">
                <Header type="display" size="5">
                  {crumbs?.icon}
                  {crumbs?.title}
                  <span className="current-count">
                    {currentCount && currentCount > 0
                      ? ` (${currentCount})`
                      : null}
                  </span>
                </Header>
                <Text type="helper">{crumbs?.description}</Text>
              </div>
            ) : null}
          </div>
          <div className="admin-body">
            <Routes>
              <Route
                exact
                path="/"
                id="mobile"
                element={<MobileUsers {...props} {...globalProps} />}
              />
              <Route
                exact
                path="/location"
                id="location"
                element={<Location {...props} {...globalProps} />}
              />
              <Route
                exact
                path="/last-location/:id"
                id="last-location"
                element={<LastLocation {...props} {...globalProps} />}
              />
              <Route
                exact
                path="/kyck-emails"
                id="kyck"
                element={<KyckEmails {...props} {...globalProps} />}
              />
            </Routes>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default Admin;
