import React, { useState, useEffect } from "react";
import { Table, Input } from "antd";
import { message } from "_styleguide";
import { getColumns } from "./_data/Columns";
import { getErrors } from "_helpers/api";
import axios from "axios";
import "./KyckEmails.scss";

const defaultParams = {
  results: 10,
  page: 1,
  sortField: "updatedAt",
  sortOrder: "descend",
  name: undefined,
};

function KyckEmails(props) {
  const [state, setState] = useState({
    loading: true,
    data: [],
    pagination: { pageSize: 10 },
  });
  const [search, setSearch] = useState();

  const fetchData = async (params = defaultParams) => {
    try {
      const variables = { ...params };
      const apiResults = await axios.get(
        `${process.env.REACT_APP_API_PATH}/kyck/emails`,
        {
          params: {
            ...variables,
            search: search,
          },
        },
      );
      const pagination = { ...state.pagination };
      pagination.total = apiResults?.data?.pageTotal;
      pagination.totalAll = apiResults?.data?.total;
      setState({
        loading: false,
        data: apiResults?.data?.data || [],
        pagination: pagination,
      });
    } catch (err) {
      message.error(getErrors(err) || "Error loading entries, try again");
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...state.pagination };
    pager.current = pagination.current;
    setState({ pagination: pager });
    fetchData({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      search: search,
    });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = getColumns();

  return (
    <div>
      <div className="filter-bar">
        <Input.Search
          size="large"
          placeholder="Search..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onSearch={() => fetchData({ ...defaultParams, search })}
          style={{ width: 304 }}
        />
      </div>
      <Table
        bordered
        size="small"
        rowSelection={null}
        columns={columns}
        loading={state.loading}
        rowKey={(record) => record._id}
        dataSource={state?.data}
        pagination={state.pagination}
        onChange={handleTableChange}
        scroll={{ x: "max-content" }}
        locale={{
          emptyText: state.loading ? "Loading entries..." : "No entries found",
        }}
      />
    </div>
  );
}

export default KyckEmails;
