import React from 'react';
import './Header.scss';

const Header = incomingProps => {
  const props = { ...incomingProps };
  props.className = 'sky-header ' + (props.className || '');

  // Handle Type (light, normal (default))
  if (props.type === 'display' && props.weight) {
    props.className += ' display-' + props.weight;
    delete props.weight;
  }

  const headerType = size => {
    switch (size) {
      case '1':
        return <h1 {...props}>{props.children}</h1>;
      case '2':
        return <h2 {...props}>{props.children}</h2>;
      case '3':
        return <h3 {...props}>{props.children}</h3>;
      case '4':
        return <h4 {...props}>{props.children}</h4>;
      case '5':
        return <h5 {...props}>{props.children}</h5>;
      default:
        return <h1 {...props}>{props.children}</h1>;
    }
  };

  return headerType(props.size);
};

export default Header;
