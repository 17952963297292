import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { APIProvider, Map } from "@vis.gl/react-google-maps";
import Marker from "./_components/Marker/Marker";
import { IconMap2 } from "@tabler/icons-react";
import axios from "axios";
import { message, Loading } from "_styleguide";
import { getErrors } from "_helpers/api";
import moment from "moment";
import "./LastLocation.scss";

function LastLocation(props) {
  const [state, setState] = useState({
    loading: true,
    data: undefined,
    default_coords: { lat: 28.538336, lng: -81.379234 },
    location_date: null,
  });
  const { id } = useParams();

  let mapSize = { width: "calc(100vw - 240px)", height: "calc(100vh - 60px)" };
  if (props.collapsed) {
    mapSize = { width: "calc(100vw - 80px)", height: "calc(100vh - 60px)" };
  }

  const fetchData = async () => {
    try {
      const apiResults = await axios.get(
        `${process.env.REACT_APP_API_PATH}/mobile/get-application`,
        {
          params: {
            application_id: id,
          },
        },
      );
      const momentData = moment(
        Date.parse(apiResults.data?.last_location?.location_date),
      );

      setState({
        loading: false,
        data: apiResults?.data || undefined,
        default_coords: {
          lat: apiResults.data?.last_location?.latitude,
          lng: apiResults.data?.last_location?.longitude,
        },
        location_date: momentData.format("M/D/YY hh:mm A"),
      });
      props.setCrumbs({
        title: apiResults?.data?.first_name + " " + apiResults?.data?.last_name,
        icon: <IconMap2 />,
        description:
          "Last known location as of " + momentData.format("M/D/YY hh:mm A"),
      });
    } catch (err) {
      message.error(getErrors(err) || "Error loading entries, try again");
    }
  };

  useEffect(() => {
    props.setCrumbs(undefined);
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log(state);

  return state?.loading ? (
    <Loading />
  ) : (
    <div className="admin-location-map">
      <APIProvider apiKey={process.env.REACT_APP_GOOGLE}>
        <Map
          mapId={"bf51a910020fa25a"}
          style={mapSize}
          defaultCenter={state.default_coords}
          defaultZoom={7}
          gestureHandling={"greedy"}
          disableDefaultUI={true}
        >
          {state.data?.last_location && (
            <Marker
              name={state?.data?.first_name + " " + state?.data?.last_name}
              lat={state.data?.last_location?.latitude}
              lng={state.data?.last_location?.longitude}
              date={state.location_date}
            />
          )}
        </Map>
      </APIProvider>
    </div>
  );
}

export default LastLocation;
