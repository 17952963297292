import { Link } from "react-router-dom";
import moment from "moment";

export const getColumns = () => {
  return [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name, data) => (
        <div>
          <div>
            <b>
              {data.first_name} {data.last_name}
            </b>
          </div>
          <div className="helper">{data._id}</div>
        </div>
      ),
    },
    {
      title: "Contact",
      dataIndex: "phone",
      key: "phone",
      render: (phone, data) => (
        <div>
          <div>
            <b>Phone:</b>&nbsp;{phone}
          </div>
          <div>
            <b>Email:</b>&nbsp;{data.email}
          </div>
        </div>
      ),
    },
    {
      title: "Code",
      dataIndex: "verification",
      key: "verification",
      render: (verification) => <b>{verification}</b>,
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      render: (location, data) => {
        if (data.last_location) {
          return <Link to={"/last-location/" + data._id}>Last Location</Link>;
        }
        return location ? <>Approved</> : <>Denied</>;
      },
    },
    {
      title: "Created at?",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (updatedAt) => {
        const momentData = moment(Date.parse(updatedAt));
        return momentData.format("M/D/YY hh:mm A");
      },
    },
  ];
};
