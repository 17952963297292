import React from 'react';
import './Break.scss';

const Break = incomingProps => {
  const props = { ...incomingProps };
  props.className = 'sky-break ' + (props.className || '');

  return <div {...props} />;
};

export default Break;
