import { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import PersonalInformation from "./PersonalInformation/PersonalInformation";
import Location from "./Location/Location";
import Quote from "./Quote/Quote";
import CarInformation from "./CarInformation/CarInformation";
import Income from "./Income/Income";
import BankInformation from "./BankInformation/BankInformation";
import Identification from "./Identification/Identification";
import Verification from "./Verification/Verification";
import { getForm } from "_helpers/form";
import "./Application.scss";

function Application(props) {
  const navigate = useNavigate();
  let location = useLocation();
  const path = location?.pathname;

  const flow = {
    "/": {
      name: "verification",
      progress: 0,
      next: "/personal",
      previous: null,
    },
    "/personal": {
      name: "personal",
      progress: 20,
      next: "/car",
      previous: null,
    },
    "/car": {
      name: "car",
      progress: 40,
      next: "/quote",
      previous: "/personal",
    },
    "/quote": {
      name: "quote",
      progress: 40,
      next: "/location",
      previous: null,
    },
    "/location": {
      name: "location",
      progress: 60,
      next: "/income",
      previous: null,
    },
    "/income": {
      name: "income",
      progress: 70,
      next: "/bank",
      previous: "/location",
    },
    "/bank": {
      name: "bank",
      progress: 83,
      next: "/identification",
      previous: "/income",
    },
    "/identification": {
      name: "identification",
      progress: 95,
      next: "/something",
      previous: "/bank",
    },
  };

  const pageProps = {
    ...flow[path],
  };

  useEffect(() => {
    const currentForm = getForm();
    if (currentForm?.verification?.verified) {
      navigate("/personal");
    } else {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="app-box">
      <Routes>
        <Route
          exact
          path="/"
          id="verification"
          element={<Verification {...props} {...pageProps} />}
        />
        <Route
          exact
          path="/personal"
          id="personal"
          element={<PersonalInformation {...props} {...pageProps} />}
        />
        <Route
          exact
          path="/car"
          id="car"
          element={<CarInformation {...props} {...pageProps} />}
        />
        <Route
          exact
          path="/quote"
          id="quote"
          element={<Quote {...props} {...pageProps} />}
        />
        <Route
          exact
          path="/location"
          id="location"
          element={<Location {...props} {...pageProps} />}
        />
        <Route
          exact
          path="/income"
          id="income"
          element={<Income {...props} {...pageProps} />}
        />
        <Route
          exact
          path="/bank"
          id="bank"
          element={<BankInformation {...props} {...pageProps} />}
        />
        <Route
          exact
          path="/identification"
          id="identification"
          element={<Identification {...props} {...pageProps} />}
        />
        {/*        <Route exact path="/test" element={<Test {...props} />} />
        <Route exact path="/sign-up" element={<Signup {...props} />} />*/}
      </Routes>
    </div>
  );
}

export default Application;
