import axios from 'axios';
import { flatten } from '_assets/js/helpers';

export const saveApplication = async (cms, application) => {

  let saveResults = null;
  const data = { cms, application: flatten(application) };
  try {
    saveResults = await axios.post(`${process.env.REACT_APP_API_PATH}/application`, data);
    console.log(saveResults);
  } catch (err) {
    console.log(err);
  }


  return data;
};