import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '_styleguide';
import { hasCategory, saveForm } from '_helpers/form';
import { saveApplication } from '_helpers/application';
import './Actions.scss';

function Actions(props) {
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);

  const handleNext = async (which) => {
    const category = props.name;
    if (hasCategory(category)) {
      setIsSaving(true);
      try {
        const saveData = await saveForm({ ...props.form }, category);
        if (props.application) {
          await saveApplication(props.application, saveData);
        }    
      } catch (err) {
        console.log(err);
      }
      setIsSaving(false);
    }
    navigate(props[which]);
  };

  return (
    <div className="sky-actions">
      { props.previous ? (
        <Button loading={isSaving} onClick={() => handleNext('previous')} type="secondary" size="large" block>Back</Button>
      ) : null }
      { props.next ? (
        <Button loading={isSaving} onClick={() => handleNext('next')} type="primary" size="large" block>Next</Button>
      ) : null }
    </div>
  );
}

export default Actions;
