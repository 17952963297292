import React from 'react';
import './Label.scss';

const Label = incomingProps => {
  const props = { ...incomingProps };
  props.className = 'sky-label ' + (props.className || '');

  return <label {...props} />;
};

export default Label;
