import React from 'react';
import './Subheader.scss';

const Subheader = incomingProps => {
  const props = { ...incomingProps };
  props.className = 'sky-subheader ' + (props.className || '');

  return <div {...props} />;
};

export default Subheader;
