import React, { useState } from 'react';
import { 
  Actions,
  Break,
  Header,
  Input,
  InputNumber,
  Label,
  Progress,
  Subheader 
} from '_styleguide';
import { FormatPhone } from '_assets/js/helpers';
import { getForm } from '_helpers/form';

function PersonalInformation(props) {
  const [form, setForm] = useState(getForm('personal'));

  // Update form by field and value
  const u = (f, v) => { 
    const n = {...form}; 
    n[f] = f === 'phone' ? FormatPhone(v) : v; 
    setForm(n); 
  };

  return (
    <div className="step-start">
      <Progress className="sky-progress" percent={props.progress} showInfo={false} />
      <Header>General Information</Header>
      <Subheader>Tell us about yourself</Subheader>
      <div className="app-form">
        <div className="form-row">
          <div>
            <Label htmlFor="first_name">First name</Label>
            <Input
              id="first_name"
              value={form.first_name} 
              onChange={e => u('first_name', e.target.value)} 
              size="large" 
            />
          </div>
          <div>
            <Label htmlFor="last_name">Last name</Label>
            <Input
              id="last_name"
              value={form.last_name} 
              onChange={e => u('last_name', e.target.value)} 
              size="large" 
            />
          </div>
        </div>
        <div className="form-item">
          <Label htmlFor="email">Email address</Label>
          <Input
            id="email"
            value={form.email} 
            onChange={e => u('email', e.target.value)} 
            size="large" 
          />
        </div>
      </div>
      <Break />
      <Subheader>How much are you looking for?</Subheader>
      <div className="app-form">
        <div className="form-item">
          <Label htmlFor="amount">Loan Amount</Label>
          <InputNumber
            id="amount"
            min={1}
            max={10000000}
            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            style={{ width: '100%' }}
            value={form.amount} 
            onChange={v => u('amount', v)}
            size="large" 
          />
        </div>
      </div>
      <Actions {...props} form={form} application={['zoho', 'vergent']} />
    </div>
  );
}

export default PersonalInformation;
