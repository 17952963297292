import React, { useEffect, useState } from "react";
import { APIProvider, Map } from "@vis.gl/react-google-maps";
import Marker from "./_components/Marker/Marker";
import axios from "axios";
import { message, Loading } from "_styleguide";
import { getErrors } from "_helpers/api";
import { getCrumbs } from "../_helpers/crumbs";
import "./Location.scss";

const DEFAULT_COORDS = { lat: 37.6872, lng: -97.3301 };

function Location(props) {
  const [state, setState] = useState({
    loading: true,
    data: [],
  });

  let mapSize = { width: "calc(100vw - 240px)", height: "calc(100vh - 60px)" };
  if (props.collapsed) {
    mapSize = { width: "calc(100vw - 80px)", height: "calc(100vh - 60px)" };
  }

  const fetchData = async () => {
    try {
      const apiResults = await axios.get(
        `${process.env.REACT_APP_API_PATH}/mobile/get-locations`,
      );
      setState({
        loading: false,
        data: apiResults?.data?.locations || [],
      });
    } catch (err) {
      message.error(getErrors(err) || "Error loading entries, try again");
    }
  };

  useEffect(() => {
    props.setCrumbs(getCrumbs("location"));
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return state?.loading ? (
    <Loading />
  ) : (
    <div className="admin-location-map">
      <APIProvider apiKey={process.env.REACT_APP_GOOGLE}>
        <Map
          mapId={"bf51a910020fa25a"}
          style={mapSize}
          defaultCenter={DEFAULT_COORDS}
          defaultZoom={5}
          gestureHandling={"greedy"}
          disableDefaultUI={true}
        >
          {state.data?.map((app, i) => (
            <Marker
              name={app?.first_name + " " + app?.last_name}
              lat={app?.latitude}
              lng={app?.longitude}
              date={app.createdAt}
            />
          ))}
        </Map>
      </APIProvider>
    </div>
  );
}

export default Location;
