import React from 'react';
import { Button as AntdButton } from 'antd';
import './Button.scss';

const Button = incomingProps => {
  const props = { ...incomingProps };
  props.className = 'sky-btn ' + (props.className || '');

  // Handle Type (default, primary, secondary)
  if (props.type) {
    props.className += ' btn-' + props.type;
    delete props.type;
  }

  return <AntdButton {...props} />;
};

export default Button;
