import React from "react";
import ReactDOM from "react-dom/client";
import App from "App/App";
import "./index.scss";
import "../node_modules/@centrate-io/barn/dist/index.css";
import "../node_modules/@centrate-io/barn/dist/_fonts/fonts.scss";
import "_styleguide/styles.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.Fragment>
    <App />
  </React.Fragment>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
