import React, { useState, useEffect } from "react";
import { Search, Table } from "_styleguide";
import { message } from "_styleguide";
import { getColumns } from "./_data/Columns";
import { getErrors } from "_helpers/api";
import { getCrumbs } from "../_helpers/crumbs";
import axios from "axios";
import "./MobileUsers.scss";

const defaultParams = {
  results: 10,
  page: 1,
  sortField: "updatedAt",
  sortOrder: "descend",
  name: undefined,
};

function MobileUsers(props) {
  const [state, setState] = useState({
    loading: true,
    data: [],
    pagination: { pageSize: 10 },
  });
  const [search, setSearch] = useState();
  const [page, setPage] = useState(1);

  const fetchData = async (params = defaultParams) => {
    try {
      const variables = { ...params };
      const apiResults = await axios.get(
        `${process.env.REACT_APP_API_PATH}/mobile/users`,
        {
          params: {
            ...variables,
            search: search,
          },
        },
      );
      const pagination = { ...state.pagination };
      pagination.total = apiResults?.data?.pageTotal;
      pagination.totalAll = apiResults?.data?.total;
      setPage(params?.page || 1);
      props?.setCurrentCount(pagination.totalAll);
      setState({
        loading: false,
        data: apiResults?.data?.data || [],
        pagination: pagination,
      });
    } catch (err) {
      message.error(getErrors(err) || "Error loading entries, try again");
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...state.pagination };
    pager.current = pagination.current;
    setState({ pagination: pager });
    fetchData({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      search: search,
    });
  };

  useEffect(() => {
    props.setCrumbs(getCrumbs("mobileusers"));
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = getColumns();
  const startResults = (page - 1) * defaultParams?.results + 1;
  const endResults = startResults + state?.data?.length - 1;

  console.log("data", state?.data);

  return (
    <div>
      <div className="admin-search">
        <Search
          placeholder="Search applications..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onSearch={() => {
            fetchData({ ...defaultParams, search });
          }}
          style={{ width: 304 }}
        />
      </div>
      <div className="admin-table application-table">
        <Table
          columns={columns}
          loading={state.loading}
          pagination={state.pagination}
          onChange={handleTableChange}
          rowKey={(record) => record.id}
          dataSource={state?.data || []}
          showSorterTooltip={false}
          scroll={{
            y: "calc(100vh - 217px)",
            x: "max-content",
          }}
          locale={{
            emptyText: state.loading
              ? "Loading entries..."
              : "No entries found",
          }}
        />
      </div>
      {state?.data?.length > 0 && (
        <div className="admin-pagination">
          Showing {startResults}-{endResults} of {state?.pagination?.total || 0}{" "}
        </div>
      )}
    </div>
  );
}

export default MobileUsers;
