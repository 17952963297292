import React, { useState } from 'react';
import { 
  Actions,
  Break,
  Header,
  Input,
  InputNumber,
  Label,
  Progress,
  Select,
  Subheader 
} from '_styleguide';
import { getMakes, getModels } from 'car-info';
import { getForm } from '_helpers/form';

const makes = getMakes();
const years = Array.from({ length: 76 }, (_, i) => new Date().getFullYear() - i);

function CarInformation(props) {
  const [form, setForm] = useState(getForm('car'));

  // Update form by field and value
  const u = val => setForm({...form, ...val});

  return (
    <div className="step-car-information">
      <Progress className="sky-progress" percent={props.progress} showInfo={false} />
      <Header>Car Information</Header>
      <Subheader>Provide VIN & Mileage for faster and more accurate quote</Subheader>
      <div className="app-form">
        <div className="form-item">
          <Label htmlFor="vin">VIN #</Label>
          <Input
            id="vin"
            style={{ width: '100%' }}
            value={form.vin} 
            onChange={e => u({ vin: e.target.value })}
            size="large" 
          />
        </div>
        <div className="form-item">
          <Label htmlFor="mileage">Mileage</Label>
          <InputNumber
            id="mileage"
            min={1}
            max={10000000}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            style={{ width: '100%' }}
            value={form.mileage} 
            onChange={v => u({ mileage: v })}
            size="large" 
          />
        </div>
        <Break />
        <div className="form-item">
          <Label htmlFor="make">Make</Label>
          <Select
            value={form.make} 
            onChange={v => u({ make: v, model: undefined })}
            style={{ width: '100%' }}
            options={makes.map(m => ({ value: m, label: m }))}
            size="large"
          />
        </div>
        
        <div className="form-row">
          <div>
            <Label htmlFor="model">Model</Label>
            <Select
              disabled={!form.make}
              value={form.model} 
              onChange={v => u({ model: v })}
              style={{ width: '100%' }}
              options={form.make ? getModels(form.make)?.map(m => ({ value: m, label: m })) : []}
              size="large"
            />
          </div>
          <div>
            <Label htmlFor="year">Year</Label>
            <Select
              value={form.year} 
              onChange={v => u({ year: v })}
              style={{ width: '100%' }}
              options={years.map(m => ({ value: m, label: m }))}
              size="large"
            />
          </div>
        </div>
        
      </div>
      <Actions {...props} form={form} application={['zoho', 'vergent']} />
    </div>
  );
}

export default CarInformation;

