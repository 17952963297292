import React from "react";
import { Dropdown, Flex } from "antd";
import { IconSelector } from "@tabler/icons-react";
import { logOut } from "_helpers/auth";
import SkyLogo from "_assets/img/logo.png";
import "./Company.scss";

const ROLES = {
  admin: "Admin",
  manager: "Manager",
  rep: "Sales Rep",
};

function Company({ user, collapsed }) {
  const { company } = user;
  const items = [
    {
      label: (
        <div className="drop-user">
          <h5>
            {user?.name} <span>/ {ROLES[user?.role]}</span>
          </h5>
          <p>{user?.email}</p>
        </div>
      ),
      className: "drop-user-container",
    },
    { type: "divider" },
    {
      label: <span>Sign out</span>,
      onClick: logOut,
    },
  ];

  return (
    <Flex className="company-container" vertical={true}>
      <Dropdown
        className={`company-drop ${collapsed ? "drop-collapsed" : ""}`}
        menu={{
          items,
        }}
        trigger={["click"]}
        overlayClassName="company-drop-drop"
      >
        <Flex>
          <img className="sider-logo" src={SkyLogo} alt="Sky Auto Finance" />
          {!collapsed && <h3>{company?.name}</h3>}
          <IconSelector size="18" />
        </Flex>
      </Dropdown>
    </Flex>
  );
}

export default Company;
