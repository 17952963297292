import React, { useState } from 'react';
import { 
  Actions,
  Header,
  Input,
  Label,
  Progress,
  Subheader,
  Select
} from '_styleguide';
import { getForm } from '_helpers/form';

function BankInformation(props) {
  const [form, setForm] = useState(getForm('bank'));

  // Update form by field and value
  const u = (f, v) => { 
    const n = {...form};
    n[f] = v;
    setForm(n); 
  };  

  return (
    <div className="step-loan">
      <Progress className="sky-progress" percent={props.progress} showInfo={false} />
      <Header>Bank Information</Header>
      <Subheader>Where can we send the money?</Subheader>
      <div className="app-form">
        <div className="form-row">
          <div>
            <Label htmlFor="bank_name">Bank name</Label>
            <Input
              id="bank_name"
              value={form.bank_name} 
              onChange={e => u('bank_name', e.target.value)} 
              size="large" 
            />
          </div>
          <div style={{ maxWidth: '160px' }}>
            <Label htmlFor="account_type">Account type</Label>
            <Select
              value={form.account_type} 
              onChange={v => u('account_type', v)}
              style={{ width: '100%' }}
              options={[
                { value: 'checking', label: 'Checking' },
                { value: 'savings', label: 'Savings' }
              ]}
              size="large"
            />
          </div>
        </div>
        <div className="form-item">
          <Label htmlFor="routing_number">Routing number</Label>
          <Input
            id="routing_number"
            value={form.routing_number} 
            onChange={e => u('routing_number', e.target.value)} 
            size="large" 
          />
        </div>
        <div className="form-item">
          <Label htmlFor="account_number">Account number</Label>
          <Input
            id="account_number"
            value={form.account_number} 
            onChange={e => u('account_number', e.target.value)} 
            size="large" 
          />
        </div>
      </div>
      <Actions {...props} form={form} />
    </div>
  );
}

export default BankInformation;
